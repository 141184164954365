$blackish: #525564;
$dark: #666666;
$dark3: #343a40;
$bluish: #74828F;
$sky: #96C0CE;
$gray: #BEB9B5;
$red: #FF0000;
$whitish: #FEF6EB;

$lightgreen: #B7C68B;
$pinkish: #F4F0CB;
$lightbrown: #DED29E;
$brown: #B3A580;
$darkbrown: #685642;
$white: #FFFFFF;
$cream: #FFF8DC;//#FFFFCC;
$black: #000000;
$dimlightbrown: #FFF8DC;//#FFFFDD;

$blue: #4169E1;
$blue2: #35A2EB;
$navy: #000080;
$sky2: #87CEEB;
$green: #228B22;
$olive: #808000;
$mint: #98FB98;

html {
    scroll-behavior: smooth;
}

body {
    //background-image: linear-gradient(to bottom right, $blackish, $dark);
    background: $lightbrown;
    box-shadow: 10px 10px 20px $darkbrown inset, 5px 5px 30px 5px black;
    font-family: "Montserrat";
}

.robo {
    font-family: "Roboto";
}

.mont {
    font-family: "Montserrat";
}

.rale {
    font-family: "Raleway";
}

.header {
    height: 100px;
    //background-image: linear-gradient(to left, $black, $pinkish, $black);
}

.pic-adeel {
    height: 133px;
    border-radius: 50%;
}

/* .img-flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
} */

.nav-tab {
    height: 30px;
    background: $darkbrown;
    line-height: 30px;
    font-size: 14px;
    width: 100px;
    border: 1px solid $pinkish;
    border-right: none;
    border-bottom: none;
    margin-bottom: -1px;
    //border-radius: 10px 10px 0px 0px;
}

.nav-active {
    height: 35px;
    margin-top: 0px;
    font-weight: bold;
    position: relative;
    z-index: 1;
}

.nav-inactive {
    height: 30px;
    margin-top: 5px;
    font-weight: normal;
    position: relative;
    z-index: 1;
    transition: height 0.25s ease 0s, margin-top 0.25s ease 0s;
}

.nav-inactive:hover {
    height: 35px;
    margin-top: 0px;
}

.tab-nav-link {
    color: $lightbrown;
    text-decoration: none;    
    text-shadow: 1px 1px 1px $black;
}
.tab-nav-link:hover {
    color: $white;
}

.nav-link {
    color: $pinkish;
}

.stripe {
    //height: 90px;
    line-height: 30px;
    border-top: 1px solid $pinkish;
    border-right: none;
    border-bottom: none;
    font-size: 13px;
    position: relative;
}
.bg-darkbrown {
    background-color: $darkbrown;
    color: $cream;
}
.bg-brown {
    background-color: $brown;
    color: $white;
}
.bg-lightbrown {
    background-color: $pinkish;
    color: $darkbrown;
    //text-shadow: 1px 1px 1px $darkbrown;
    font-weight: bold;
}

@media only screen and (min-width: 992px) {
    .stripe {
        position: static;
        font-size: 14px;
    }
}

.btn-top {
    border: 1px solid $darkbrown;
    border-radius: 3px;
    background-color: $cream;
    box-shadow: 3px 3px 10px 3px $lightbrown inset;
    color: $darkbrown;
    width: 75px;
    margin: 1px;
    font-size: 13px;
    font-weight: bold;
    //text-shadow: 1px 1px 1px $darkbrown;
    //transition: background-color 0s ease 0s;
}

.btn-top:hover {
    background-color: $darkbrown;
    //border: 1px solid $pinkish;
    color: $pinkish;
}

.stat {
    //height: 400px;
    color: $black;
    //text-shadow: 1px 1px 1px $black;
    //padding: 20px;
    //margin-top: 20px;
    //background-color: $lightbrown;

}

.col-stat {
    box-shadow: 3px 3px 10px $darkbrown inset, 5px 5px 30px 5px $darkbrown;
    border: 1px solid $pinkish;
    padding: 10px;
    overflow-y: auto;
    max-height: 400px;
    margin-top: 30px;
    background-color: $cream;
}

.stat-heading {
    height: 35px;
    line-height: 25px;
    background-color: $darkbrown;
    width: 100%;
    color: $white;
    padding: 5px;
    font-size: 15px;
    border-radius: 2px;
    margin-top: 5px;
    text-transform: capitalize;
    cursor: pointer;
    text-shadow: 1px 1px 1px $black;
}

.stat-table>div:nth-child(n) {
    //padding-left: 20px;
    padding: 10px;
    width: 50%;
    display: inline-block;
    border-bottom: 1px solid $darkbrown;
}

hr {
    border: 1px solid $pinkish;
}

.comment-table {
    //border: 1px solid $lightbrown;
    box-shadow: 2px 2px 10px 2px $darkbrown inset;
    //margin-bottom: 10px;
    //background-color: black;
    //border-bottom: 2px solid $lightbrown;
    padding-top: 20px;
}

.comment-table>div:nth-child(n) {
    padding-left: 10px;
    display: inline-block;
    border-bottom: 1px solid $darkbrown;
    line-height: 25px;
    padding: 10px;
}

.comment-table>div:nth-child(odd) {
    width: 25%;
}

.comment-table>div:nth-child(even) {
    width: 75%;
}

.comment {
    color: $black;
    width: 100% !important;
    font-weight: bold;
}

.proj {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: clip;
}

.bg-blakish {
    background-color: $blackish;
}

.bg-dark {
    background-color: $cream !important;
}

.bg-cream {
    background-color: $lightbrown;
}

////////////////////////////
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $lightbrown;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $darkbrown;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $brown;
}

///////////////////////////

.footer {
    margin-top: 30px;
    height: 100px;
    background-color: $pinkish;
    box-shadow: 10px 10px 20px $darkbrown inset;
    padding: 5px;
    //border: 1px solid $darkbrown;
}

.btn-footer {
    height: 20px;
    width: 75px;
    box-shadow: 2px 2px 2px 0px $brown inset;
    font-size: 10px;
    font-weight: bold;
    border: none;
    //margin-bottom: 1px;
}

.social>div {
    //text-align: center;
    width: 50%;
    height: 75px;
    display: inline-block;
}

.social>div>a>img {
    height: 100%;
}

.copyright-text {
    font-size: 12px;
}

.btn-bottom>button {
    border: 1px solid $darkbrown;
    border-radius: 3px;
    background-color: transparent;
    box-shadow: 2px 2px 3px $darkbrown inset;
    color: $black;
    width: 85px;
    margin: 1px;
    font-size: 13px;
    //transition: background-color 0s ease 0s;
}

.btn-bottom>button:hover {
    background-color: $darkbrown;
    border: 1px solid $blackish;
    color: $pinkish;
}

.portfolio {
    background-color: $olive;
    height: 500px;
}

.project {
    width: 90%;
    color: $darkbrown;
    float: left;
    padding-left: 10px;
}

.project:hover {
    color: $cream !important;
    background-color: $darkbrown;
    cursor: pointer;
}

.project-sr {
    width: 10%;
    text-align: center;
    color: $white;
    float: left;
    background-color: $brown;
    box-shadow: 3px 3px 10px $darkbrown inset, 2px 2px 10px 2px $black;
}

.tech {
    width: 90%;
    color: $darkbrown;
    float: left;
    padding-left: 10px;
}

.tech:hover {
    color: $cream !important;
    background-color: $darkbrown;
    cursor: pointer;
}

.tech-sr {
    width: 10%;
    text-align: center;
    color: $white;
    float: left;
    background-color: $brown;
    box-shadow: 3px 3px 10px $darkbrown inset, 2px 2px 10px 2px $black;
}
#get-height {
    height:auto;
}
.set-height {
    max-height:470px;
}
.col-portfolio {
    box-shadow: 3px 3px 10px $darkbrown inset, 5px 5px 30px 5px $darkbrown;
    border: 1px solid $pinkish;
    padding: 10px;
    overflow-y: auto;
    //max-height: 400px; //use media quiries to set height for mobile view
    background-color: $cream;
}

.col-portfolio-video {
    box-shadow: 3px 3px 10px $darkbrown inset, 5px 5px 30px 5px $darkbrown;
    border: 1px solid $pinkish;
    padding: 10px;
    overflow-y: auto;
    max-height: 420px;
    background-color: $cream;
}

.img-dot {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $lightbrown;
    margin-top: 7px;
    margin-right: 3px;
    display: inline-block;
}

/* Fading animation */
.fade-slide {
    animation-name: fade-slide;
    animation-duration: 1s;
}

@keyframes fade-slide {
    from {
        opacity: .1;
        /* transform: rotateX(-90deg);
        transform-origin: top;
        transition: transform 0.5s ease-in-out; */
    }

    to {
        opacity: 1;
        //transform: rotateX(0deg);
    }
}

/* .bottom-paper {
    border: 1px solid #FFBE00;
    transform: rotate(1deg);
    box-shadow: 5px 5px 20px $darkbrown inset, 1px 1px 3px 1px $darkbrown;
    background-color: $lightbrown;
    opacity: 1;
    transition: opacity 1s;
} */
.project-info {
    height: 90%;
    width: 90%;
    position: absolute;
    //top: 10px;
    bottom: 30px;
    margin-left: 5%;
    //transform: rotate(1deg);
}

.project-info div {
    height: 0%;
    width: 100%;
    transition: height 0.5s, padding 0.5s;
    color: $black;
    background-color: $cream;
    overflow: hidden;
    //opacity: 0.75;
    text-align: left;
    padding: 0px;
}

.project-info:hover div {
    height: 100%;
    padding: 10px;
    box-shadow: 2px 2px 20px $brown inset, 1px 1px 3px 1px $darkbrown;
}

.project-info-txt {
    overflow-y: auto !important;
    transform: rotate(-1deg);
    font-size: 10pt;
    //font-family: "Indie Flower";
    //font-family: "Kaushan Script";
    text-shadow: 1px 1px 1px $cream;
}
@media only screen and (min-width: 1200px) {
    .project-info-txt {
        overflow-y: hidden !important;
    }
}

.bg-header {
    //background-image: url("./gallery/imgs/digital-eye.jpg");
    background-color: $dimlightbrown;
}

.vid-project {
    white-space: pre-wrap;
    overflow: auto;
    color: $darkbrown;
    margin: 10px;
    padding: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.75);
}

.vid-project>a {
    color: $lightbrown;
    padding: 5px;
    margin: 2px;
    background-color: $blackish;
    box-shadow: 3px 3px 5px $lightbrown inset, 2px 2px 2px 2px $darkbrown;
}

.col-blog {
    box-shadow: 3px 3px 10px $darkbrown inset, 5px 5px 30px 5px $darkbrown;
    border: 1px solid $pinkish;
    padding: 10px;
    overflow-y: auto;
    //max-height: 420px;
}

.blog {
    width: 90%;
    color: $darkbrown;
    float: left;
    padding-left: 10px;
}

.blog:hover {
    color: $cream !important;
    background-color: $darkbrown;
    cursor: pointer;
}

.blog-sr {
    width: 10%;
    text-align: center;
    color: $white;
    float: left;
    background-color: $brown;
    box-shadow: 3px 3px 10px $darkbrown inset, 2px 2px 10px 2px $black;
}

.blog-text {
    color: $darkbrown;
    padding: 10px;
}

code {
    color: $darkbrown !important;
}

.myContainer {
    border: 1px double gray;
    width:50%;
    height:100px;
    position:relative;
}

.myParagraph {
    top: 50%;
    transform: translateY(-50%);
    position:absolute;
}

.blog-text-shadow {
    color: $white;
    text-shadow: 1px 1px 2px black;
}

.nav-tabs > .nav-item > .active {
    background-color: $lightbrown;
    color: $darkbrown !important;
}

.nav-tabs > .nav-item > .nav-link {
    width: 100px;
    text-align: center;
    color: $brown;
}

.nav-tabs > .nav-item > .nav-link:hover {
    color: $darkbrown !important;
}

.monitor-statement {
    color: $black;
    background-color: $brown;
    font-size: 13px;
    font-weight: bold;
    padding: 5px 10px;
    text-shadow: 1px 1px 1px $pinkish;
}

.header-chart {
    filter: opacity(5%);
    transition: filter 1s ease-out;
}
.header-chart:hover {
    filter: none;
}